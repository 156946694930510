// 档位
class Gear {
    // 档位名称
    name = ''

    // 最小码数
    min = 0

    // 最大码数
    max = 20

    // 答案
    answer = ''
}



class FirstGear extends Gear {
    name = '一档'
    min = 0
    max = 20
    answer = 'A'
}


class TwoGear extends Gear {
    name = '二档'
    min = 10
    max = 30
    answer = 'B'
}

class ThreeGear extends Gear {
    name = '三档'
    min = 20
    max = 40
    answer = 'C'
}

class Four extends Gear {
    name = '四档'
    min = 30
    max = 60
    answer = 'D'
}



export { FirstGear, TwoGear, ThreeGear, Four };