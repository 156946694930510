import React, { useState } from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import { FirstGear, TwoGear, ThreeGear, Four } from './speed.js';

function App() {
  const [randomNumber, setRandomNumber] = useState(Math.floor(Math.random() * 61));
  const [selectedGears, setSelectedGears] = useState([]);
  const [result, setResult] = useState('');
  const [isSubmitted, setIsSubmitted] = useState(false);
  const [countdown, setCountdown] = useState(null);
  const [timer, setTimer] = useState(null);

  const gears = [
    new FirstGear(),
    new TwoGear(),
    new ThreeGear(),
    new Four()
  ];

  const handleCheckboxChange = (answer) => {
    if (selectedGears.includes(answer)) {
      setSelectedGears(selectedGears.filter(item => item !== answer));
    } else {
      setSelectedGears([...selectedGears, answer]);
    }
  };

  const checkAnswer = () => {
    setIsSubmitted(true);
    // 获取当前速度下的正确档位
    const correctGears = gears.filter(gear => 
      randomNumber >= gear.min && randomNumber <= gear.max
    );

    // 获取正确档位的答案数组
    const correctAnswers = correctGears.map(gear => gear.answer);
    const sortedCorrectAnswers = [...correctAnswers].sort();
    const sortedSelectedGears = [...selectedGears].sort();

    const isCorrect = 
      sortedCorrectAnswers.length === sortedSelectedGears.length &&
      sortedCorrectAnswers.every((answer, index) => answer === sortedSelectedGears[index]);

    if (isCorrect) {
      setResult('回答正确！');
    } else {
      const correctGearsInfo = correctGears.map(gear => 
        `${gear.name}(${gear.min}-${gear.max}码)`
      ).join('、');
      
      setResult(`回答错误！\n当前速度 ${randomNumber} 码对应的档位是：${correctGearsInfo}`);
    }

    let timeLeft = 5;
    setCountdown(timeLeft);
    
    const newTimer = setInterval(() => {
      timeLeft -= 1;
      setCountdown(timeLeft);
      
      if (timeLeft === 0) {
        clearInterval(newTimer);
        handleRestart();
      }
    }, 1000);
    
    setTimer(newTimer);
  };

  const handleRestart = () => {
    if (timer) {
      clearInterval(timer);
      setTimer(null);
    }
    setSelectedGears([]);
    setResult('');
    setRandomNumber(Math.floor(Math.random() * 61));
    setIsSubmitted(false);
    setCountdown(null);
  };

  return (
    <div style={{ padding: '20px', textAlign: 'center' }}>
      <div style={{ 
        fontSize: '24px', 
        fontWeight: 'bold', 
        margin: '20px 0' 
      }}>
        当前速度：{randomNumber} 码
      </div>

      <div style={{ margin: '20px 0' }}>
        {gears.map((gear) => (
          <label key={gear.answer} style={{ 
            marginRight: '15px',
            opacity: isSubmitted ? 0.6 : 1
          }}>
            <input
              type="checkbox"
              checked={selectedGears.includes(gear.answer)}
              onChange={() => handleCheckboxChange(gear.answer)}
              disabled={isSubmitted}
            />
            {gear.name}
          </label>
        ))}
      </div>

      <div style={{ 
        display: 'flex', 
        justifyContent: 'center', 
        gap: '10px',
        marginBottom: '10px'
      }}>
        <button 
          onClick={checkAnswer} 
          disabled={isSubmitted} 
          style={{ opacity: isSubmitted ? 0.6 : 1 }}
        >
          提交答案
        </button>
        <button onClick={handleRestart}>
          {isSubmitted ? `${countdown}秒后重新开始` : '重新开始'}
        </button>
      </div>
      
      {result && (
        <p style={{ 
          whiteSpace: 'pre-line',
          margin: '10px 0',
          color: result.includes('错误') ? '#ff4d4f' : '#52c41a'
        }}>
          {result}
        </p>
      )}
    </div>
  );
}

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>
);
